import React, { useState, useEffect, useReducer } from "react";
import { useHistory, useNavigate } from "react-router-dom";
import { Link, useParams } from "react-router-dom";

import Joi from "joi-browser";

import { Bars } from "react-loader-spinner";

import { toast } from "react-toastify";
import TextInputLabel from "../../../reusable/form/textinputlabel";
import Input from "../../../reusable/form/Input";

//maybe add the scehema here
//TODO: flatten the scehema so just the current Address
//no validation at first (layered cake)
const CurrentAddressAppliForm = ({ tenantProfile, setTenantProfile }) => {
  const [currentAddressDetail, setCurrentAddressDetail] = useState(
    tenantProfile.tenant_application.current_address_detail || {
      address_type: "",
      street_address: "",
      city_town: "",
      parish_state: "",
      country_name: "",
      zip_postal_code: "",
      date_in: "",
      land_lord_name: "",
      land_lord_phone: "",
      monthly_rent: "",
      record_time_stamp: null,
    }
  );

  useEffect(() => {
    setCurrentAddressDetail(
      tenantProfile.tenant_application.current_address_detail || {
        address_type: "",
        street_address: "",
        city_town: "",
        parish_state: "",
        country_name: "",
        zip_postal_code: "",
        date_in: "",
        land_lord_name: "",
        land_lord_phone: "",
        monthly_rent: "",
        record_time_stamp: null,
      }
    );
  }, [tenantProfile.tenant_application.current_address_detail]);

  const handleChange = (eventOrValue, name) => {
    // Check if eventOrValue is an event object or a direct value
    const isEvent = eventOrValue && eventOrValue.target;
    const fieldName = isEvent ? eventOrValue.target.name : name;
    const value = isEvent ? eventOrValue.target.value : eventOrValue;

    setCurrentAddressDetail((prevAddressDetail) => {
      const newAddressDetail = { ...prevAddressDetail };

      if (fieldName in newAddressDetail) {
        newAddressDetail[fieldName] = value;
      } else {
        // Handle nested objects
        for (let key in newAddressDetail) {
          if (
            typeof newAddressDetail[key] === "object" &&
            newAddressDetail[key] !== null &&
            fieldName in newAddressDetail[key]
          ) {
            newAddressDetail[key][fieldName] = value;
          }
        }
      }

      return newAddressDetail;
    });

    setTenantProfile((prevProfile) => {
      const newProfile = { ...prevProfile };
      const newAddressDetail = {
        ...newProfile.tenantApplication.currentAddressDetail,
      };

      if (fieldName in newAddressDetail) {
        newAddressDetail[fieldName] = value;
      } else {
        // Handle nested objects
        for (let key in newAddressDetail) {
          if (
            typeof newAddressDetail[key] === "object" &&
            newAddressDetail[key] !== null &&
            fieldName in newAddressDetail[key]
          ) {
            newAddressDetail[key][fieldName] = value;
          }
        }
      }

      newProfile.tenantApplication.currentAddressDetail = newAddressDetail;

      return newProfile;
    });
  };

  return (
    <React.Fragment>
      {/* prefil things from the prescreening */}
      {/*
     addressType: Joi.string().allow(''),
    streetAddress: Joi.string().required().min(5),
    cityTown: Joi.string().allow(''),
    parishState: Joi.string().allow(''),
    countryName: Joi.string().allow(''),
    zipPostalCode: Joi.string().allow(''),
    dateIn: Joi.string().allow(''),
    landLordName: Joi.string().allow(''),
    landLordPhone: Joi.string().allow(''),
    monthlyRent: Joi.string().allow(''),
    recordTimeStamp: Joi.date().allow(null),  
  */}

      <div className="row">
        <div className="col-lg-12">
          <div className="card-header d-flex flex-column">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4 className="card-title mb-0">Current Address</h4>
                <h6
                  className="card-subtitle text-muted"
                  style={{ paddingTop: "0.5%" }}
                >
                  {/*subHeadline */}
                </h6>
              </div>

              {/* Save Form at anytime located to the right of the form corner*/}
              {/*No validation since its saving what we currently have, when submit you have
                        to select the campaign (landlord your sending it to from your list of campaigns that are active) */}
              <div>{/* The right side*/}</div>
            </div>
            {/*Revisit validation later */}
            <div className="row gy-4">
              <div className="col-xxl-3 col-md-6">
                <div>
                  <TextInputLabel
                    name="street_address"
                    value={currentAddressDetail.street_address}
                    label="Street Address"
                    //error={streetAddressError}
                    onChange={handleChange}
                    disabled={false}
                  />
                </div>
              </div>
              {/*end col*/}
              <div className="col-xxl-3 col-md-6">
                <TextInputLabel
                  name="city_town"
                  value={currentAddressDetail.city_town}
                  label="City/Town"
                  //error={cityTownError}
                  onChange={(value) => handleChange(value, "city_town")}
                  disabled={false}
                />
              </div>
              <div className="col-xxl-3 col-md-6">
                <TextInputLabel
                  name="parish_state"
                  value={currentAddressDetail.parish_state}
                  label="Parish/State"
                  //error={parishStateError}
                  onChange={handleChange}
                  disabled={false}
                />
              </div>
              <div className="col-xxl-3 col-md-6">
                <TextInputLabel
                  name="country_name"
                  value={currentAddressDetail.country_name}
                  label="Country"
                  // error={countryError}
                  onChange={handleChange}
                  disabled={false}
                />
              </div>
              {/*  zipPostalCode*/}
              <div className="col-xxl-3 col-md-6">
                <TextInputLabel
                  name="zip_postal_code"
                  value={currentAddressDetail.zip_postal_code}
                  label="Zip/Postal Code"
                  //error={zipPostalCodeError}
                  onChange={handleChange}
                  disabled={false}
                />
              </div>

              {/*dateIn*/}
              <div className="col-xxl-3 col-md-6">
                <Input
                  showIcon
                  name="date_in"
                  value={currentAddressDetail.date_in}
                  label="Date In"
                  onChange={(date) => handleChange(date, "date_in")}
                  type={"date"}
                />
              </div>

              {/*landLordName*/}
              <div className="col-xxl-3 col-md-6">
                <TextInputLabel
                  name="land_lord_name"
                  value={currentAddressDetail.land_lord_name}
                  label="LandLord Name"
                  // error={landLordNameError}
                  onChange={handleChange}
                  disabled={false}
                />
              </div>

              {/*landLordPhone*/}
              <div className="col-xxl-3 col-md-6">
                <TextInputLabel
                  name="land_lord_phone"
                  value={currentAddressDetail.land_lord_phone}
                  label="LandLord Phone"
                  // error={landLordPhoneError}
                  onChange={handleChange}
                  disabled={false}
                  type={"number"}
                />
              </div>

              {/*monthlyRent*/}
              <div className="col-xxl-3 col-md-6">
                <TextInputLabel
                  name="monthly_rent"
                  value={currentAddressDetail.monthly_rent}
                  label="Monthly Rent"
                  // error={monthlyRentError}
                  onChange={handleChange}
                  disabled={false}
                />
              </div>
            </div>
          </div>
          {/* Form start */}

          {/* Form end */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default CurrentAddressAppliForm;
