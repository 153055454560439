import React, { useState, useEffect, useReducer } from "react";
import { useHistory, useNavigate } from "react-router-dom";
import { Link, useParams } from "react-router-dom";

import Joi from "joi-browser";

import { Bars } from "react-loader-spinner";

import { toast } from "react-toastify";

import TextInputLabel from "../../../reusable/form/textinputlabel";
import SelectInputLabel from "../../../reusable/form/selectinputlabel";
import Input from "../../../reusable/form/Input";
//if any applicant is over 18, they will have to fill out the full information about
//where they have lived,their identification, and their employment history, their background information

//the applicant is count is set from the prescreening
//MARK: Need to style it
//Make it in isolation first then the data back to the main form after
const OtherOccupantAppliForm = ({ tenantProfile, setTenantProfile }) => {
  //determine if the applicant is over 18 after they enter the birthyear
  const calculateAge = (birthYear) => {
    const currentYear = new Date().getFullYear();
    return currentYear - birthYear;
  };

  //need to get this from the database
  const relationshipTypes = [
    "Spouse",
    "Child",
    "Friend",
    "Parent",
    "Sibling",
    "Other",
  ];

  const [occupants, setOccupants] = useState(
    tenantProfile.tenantApplication.otherOccupants || [
      {
        first_name: "",
        last_name: "",
        birth_year: "",
        relationship: "",
        is_over_18: false,
        email_address: "",
      },
    ]
  );

  useEffect(() => {
    setOccupants(
      tenantProfile.tenantApplication.otherOccupants || [
        {
          first_name: "",
          last_name: "",
          birth_year: "",
          relationship: "",
          is_over_18: false,
          email_address: "",
        },
      ]
    );
  }, [tenantProfile.tenantApplication.otherOccupants]);

  const handleChange = (i, event) => {
    const values = [...occupants];
    values[i][event.target.name] = event.target.value;

    if (event.target.name === "birth_year") {
      values[i].is_over_18 = calculateAge(event.target.value) >= 18;
    }

    setOccupants(values);
  };

  const handleAdd = () => {
    const values = [...occupants];
    values.push({
      first_name: "",
      last_name: "",
      birth_year: "",
      relationship: "",
      is_over_18: false,
      email_address: "",
    });
    setOccupants(values);

    setTenantProfile((prevProfile) => ({
      ...prevProfile,
      tenantApplication: {
        ...prevProfile.tenantApplication,
        otherOccupants: values,
      },
    }));
  };

  const handleRemove = (i) => {
    const values = [...occupants];
    values.splice(i, 1);
    setOccupants(values);

    setTenantProfile((prevProfile) => ({
      ...prevProfile,
      tenantApplication: {
        ...prevProfile.tenantApplication,
        otherOccupants: values,
      },
    }));
  };

  return (
    <div className="row">
      {occupants.length === 0 && (
        <div className="row gy-4  pt-3">
          {/* Message to tell the user to press Add Employment and Income Button to add this info*/}
          <div className="col-xxl-12 col-md-12">
            <div
              className="alert alert-info alert-dismissible fade show"
              role="alert"
            >
              <i className="mdi mdi-bullseye-arrow me-2"></i>
              <strong>
                Press Add Occupant Button to add other occupants if applicable.
              </strong>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
          </div>
        </div>
      )}

      <div className="col-lg-12">
        <div className="card-header d-flex flex-column">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h4 className="card-title mb-0">Other Occupants</h4>
              <h6
                className="card-subtitle text-muted"
                style={{ paddingTop: "0.5%" }}
              >
                {/*subHeadline */}
              </h6>
            </div>

            {/* Save Form at anytime located to the right of the form corner*/}
            {/*No validation since its saving what we currently have, when submit you have
                        to select the campaign (landlord your sending it to from your list of campaigns that are active) */}
            <div>{/* The right side*/}</div>
          </div>
          {/*Revisit validation later */}
          <div className="col-lg-12">
            {occupants.map((occupant, idx) => (
              <div className="row gy-4" key={`${occupant}-${idx}`}>
                <div className="col-xxl-3 col-md-6">
                  <TextInputLabel
                    name="first_name"
                    label="First Name"
                    value={occupant.first_name}
                    onChange={(e) => handleChange(idx, e)}
                  />
                </div>
                <div className="col-xxl-3 col-md-6">
                  <TextInputLabel
                    name="last_name"
                    label="Last Name"
                    value={occupant.last_name}
                    onChange={(e) => handleChange(idx, e)}
                  />
                </div>
                <div className="col-xxl-3 col-md-6">
                  <TextInputLabel
                    type="text"
                    label="Birth Year"
                    name="birth_year"
                    value={occupant.birth_year}
                    onChange={(e) => handleChange(idx, e)}
                  />
                </div>
                <div className="col-xxl-3 col-md-6">
                  <SelectInputLabel
                    name="relationship"
                    label="Relationship"
                    value={occupant.relationship}
                    onChange={(e) => handleChange(idx, e)}
                  >
                    <option value="">Select...</option>
                    {relationshipTypes.map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </SelectInputLabel>
                </div>
                {calculateAge(occupant.birth_year) >= 18 && (
                  <div className="col-xxl-3 col-md-6">
                    <TextInputLabel
                      name={`email_address`}
                      label="Email Address"
                      value={occupant.email_address}
                      onChange={(e) => handleChange(idx, e)}
                    />
                  </div>
                )}
                <div className="col-xxl-1 col-md-1">
                  <button
                    type="button"
                    className="btn btn-danger btn-icon waves-effect waves-light"
                    onClick={() => handleRemove(idx)}
                  >
                    <i class="ri-delete-bin-5-line"></i>
                  </button>
                </div>
                {/* horizontal divider line*/}
                <hr />
              </div>
            ))}
          </div>
          <div className="col-xxl-3 col-md-6">
            <button
              type="button"
              className="btn btn-success w-lg waves-effect waves-light"
              onClick={() => handleAdd()}
            >
              <i className="ri-add-fill"></i>
              Add Occupant
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtherOccupantAppliForm;
