//EmergencyContactForm

import React, { useState, useEffect, useReducer } from "react";
import Joi from "joi-browser";
import { toast } from "react-toastify";

import TextInputLabel from "../../../reusable/form/textinputlabel";
import SelectInputLabel from "../../../reusable/form/selectinputlabel";
import Input from "../../../reusable/form/Input";

const PersonalReferenceForm = ({ tenantProfile, setTenantProfile }) => {
  const relationshipTypes = [
    "Colleague/Co-Worker",
    "Coach",
    "High Ranking Officer (Military or Police)",
    "Justice of the Peace",
    "Mentor",
    "Notary Public",
    "Pastor or Religious Leader",
    "Previous Landlord",
    "Professional Acquaintance",
    "Supervisor/Manager",
    "Teacher/Professor",
    "Volunteer Supervisor",
  ];

  const [personalReferences, setPersonalReferences] = useState(
    tenantProfile.tenantApplication.personalReferences || [
      {
        reference_name: "",
        address: "",
        phone_number: "",
        contact_relationship: "",
      },
    ]
  );

  useEffect(() => {
    setPersonalReferences(
      tenantProfile.tenantApplication.personalReferences || [
        {
          reference_name: "",
          address: "",
          phone_number: "",
          contact_relationship: "",
        },
      ]
    );
  }, [tenantProfile.tenantApplication.personalReferences]);

  const handleChange = (i, event) => {
    const values = [...personalReferences];
    values[i][event.target.name] = event.target.value;

    setPersonalReferences(values);
  };

  const handleAdd = () => {
    const values = [...personalReferences];
    values.push({
      reference_name: "",
      address: "",
      phone_number: "",
      contact_relationship: "",
    });
    setPersonalReferences(values);

    setTenantProfile((prevProfile) => ({
      ...prevProfile,
      tenantApplication: {
        ...prevProfile.tenantApplication,
        personalReferences: values,
      },
    }));
  };

  const handleRemove = (i) => {
    const values = [...personalReferences];
    values.splice(i, 1);
    setPersonalReferences(values);

    setTenantProfile((prevProfile) => ({
      ...prevProfile,
      tenantApplication: {
        ...prevProfile.tenantApplication,
        personalReferences: values,
      },
    }));
  };

  return (
    <div className="row">
      {personalReferences.length === 0 && (
        <div className="row gy-4  pt-3">
          {/* Message to tell the user to press Add Employment and Income Button to add this info*/}
          <div className="col-xxl-12 col-md-12">
            <div
              className="alert alert-info alert-dismissible fade show"
              role="alert"
            >
              <i className="mdi mdi-bullseye-arrow me-2"></i>
              <strong>
                Press Add Personal Reference Button to add other reference
                contacts if applicable.
              </strong>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
          </div>
        </div>
      )}

      <div className="col-lg-12">
        <div className="card-header d-flex flex-column">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h4 className="card-title mb-0">Personal Reference</h4>
              <h6
                className="card-subtitle text-muted"
                style={{ paddingTop: "0.5%" }}
              >
                {/*subHeadline */}
              </h6>
            </div>

            {/* Save Form at anytime located to the right of the form corner*/}
            {/*No validation since its saving what we currently have, when submit you have
                        to select the campaign (landlord your sending it to from your list of campaigns that are active) */}
            <div>{/* The right side*/}</div>
          </div>
          {/*Revisit validation later */}
          <div className="col-lg-12">
            {personalReferences.map((contact, idx) => (
              <div className="row gy-4" key={`${contact}-${idx}`}>
                <div className="col-xxl-3 col-md-6">
                  <TextInputLabel
                    name="reference_name"
                    label="Reference Name"
                    value={contact.reference_name}
                    onChange={(e) => handleChange(idx, e)}
                  />
                </div>
                <div className="col-xxl-3 col-md-6">
                  <TextInputLabel
                    name="address"
                    label="Address"
                    value={contact.address}
                    onChange={(e) => handleChange(idx, e)}
                  />
                </div>
                <div className="col-xxl-3 col-md-6">
                  <TextInputLabel
                    type="text"
                    label="Phone Number"
                    name="phone_number"
                    value={contact.phone_number}
                    onChange={(e) => handleChange(idx, e)}
                  />
                </div>
                <div className="col-xxl-3 col-md-6">
                  <SelectInputLabel
                    name="contact_relationship"
                    label="Relationship"
                    value={contact.contact_relationship}
                    onChange={(e) => handleChange(idx, e)}
                  >
                    <option value="">Select...</option>
                    {relationshipTypes.map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </SelectInputLabel>
                </div>

                <div className="col-xxl-1 col-md-1">
                  <button
                    type="button"
                    className="btn btn-danger btn-icon waves-effect waves-light"
                    onClick={() => handleRemove(idx)}
                  >
                    <i class="ri-delete-bin-5-line"></i>
                  </button>
                </div>
                {/* horizontal divider line*/}
                <hr />
              </div>
            ))}
          </div>
          <div className="col-xxl-3 col-md-6">
            <button
              type="button"
              className="btn btn-success w-lg waves-effect waves-light"
              onClick={() => handleAdd()}
            >
              <i className="ri-add-fill"></i>
              Add Personal Reference
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalReferenceForm;
