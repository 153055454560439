import React, { useState, useEffect, useReducer } from "react";
import { useHistory, useNavigate } from "react-router-dom";
import { Link, useParams } from "react-router-dom";

import Joi from "joi-browser";

import { Bars } from "react-loader-spinner";

import { toast } from "react-toastify";
import TextInputLabel from "../../../reusable/form/textinputlabel";
import Input from "../../../reusable/form/Input";

//change to previousAddresses
const PreviousAddressAppliForm = ({ tenantProfile, setTenantProfile }) => {
  const [previousAddressDetail, setPreviousAddressDetail] = useState(
    tenantProfile.tenantApplication.previousAddress || {
      address_type: "",
      street_address: "",
      city_town: "",
      parish_state: "",
      country_name: "",
      zip_postal_code: "",
      date_in: "",
      land_lord_name: "",
      land_lord_phone: "",
      monthly_rent: "",
      record_time_stamp: null,
    }
  );

  useEffect(() => {
    setPreviousAddressDetail(
      tenantProfile.tenantApplication.previousAddress || {
        address_type: "",
        street_address: "",
        city_town: "",
        parish_state: "",
        country_name: "",
        zip_postal_code: "",
        date_in: "",
        land_lord_name: "",
        land_lord_phone: "",
        monthly_rent: "",
        record_time_stamp: null,
      }
    );
  }, [tenantProfile.tenantApplication.previousAddress]);

  const handleChange = (eventOrValue, name) => {
    // Check if eventOrValue is an event object or a direct value
    const isEvent = eventOrValue && eventOrValue.target;
    const fieldName = isEvent ? eventOrValue.target.name : name;
    const value = isEvent ? eventOrValue.target.value : eventOrValue;

    setPreviousAddressDetail((prevAddressDetail) => {
      const newAddressDetail = { ...prevAddressDetail };

      if (fieldName in newAddressDetail) {
        newAddressDetail[fieldName] = value;
      } else {
        // Handle nested objects
        for (let key in newAddressDetail) {
          if (
            typeof newAddressDetail[key] === "object" &&
            newAddressDetail[key] !== null &&
            fieldName in newAddressDetail[key]
          ) {
            newAddressDetail[key][fieldName] = value;
          }
        }
      }

      return newAddressDetail;
    });

    setTenantProfile((prevProfile) => {
      const newProfile = { ...prevProfile };
      const newAddressDetail = {
        ...newProfile.tenantApplication.previousAddress,
      };

      if (fieldName in newAddressDetail) {
        newAddressDetail[fieldName] = value;
      } else {
        // Handle nested objects
        for (let key in newAddressDetail) {
          if (
            typeof newAddressDetail[key] === "object" &&
            newAddressDetail[key] !== null &&
            fieldName in newAddressDetail[key]
          ) {
            newAddressDetail[key][fieldName] = value;
          }
        }
      }

      newProfile.tenantApplication.previousAddress = newAddressDetail;

      return newProfile;
    });
  };

  return (
    //need to allow the user to add as many previous addresses as they want and be able to update each in the array
    //FIST CREATE A SINGLE FORM LIKE THE CURRENT ADDRESS FORM
    <React.Fragment>
      {/* prefil things from the prescreening */}
      <div className="row">
        <div className="col-lg-12">
          <div className="card-header d-flex flex-column">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4 className="card-title mb-0">Previous Address</h4>
                <h6
                  className="card-subtitle text-muted"
                  style={{ paddingTop: "0.5%" }}
                >
                  {/*subHeadline */}
                </h6>
              </div>

              {/* Save Form at anytime located to the right of the form corner*/}
              {/*No validation since its saving what we currently have, when submit you have
                        to select the campaign (landlord your sending it to from your list of campaigns that are active) */}
              <div>{/* The right side*/}</div>
            </div>
            {/*Revisit validation later */}
            <div className="row gy-4">
              <div className="col-xxl-3 col-md-6">
                <div>
                  <TextInputLabel
                    name="street_address"
                    value={previousAddressDetail.street_address}
                    label="Street Address"
                    //error={streetAddressError}
                    onChange={handleChange}
                    disabled={false}
                  />
                </div>
              </div>
              {/*end col*/}
              <div className="col-xxl-3 col-md-6">
                <TextInputLabel
                  name="city_town"
                  value={previousAddressDetail.city_town}
                  label="City/Town"
                  //error={cityTownError}
                  onChange={handleChange}
                  disabled={false}
                />
              </div>
              <div className="col-xxl-3 col-md-6">
                <TextInputLabel
                  name="parish_state"
                  value={previousAddressDetail.parish_state}
                  label="Parish/State"
                  // error={parishStateError}
                  onChange={handleChange}
                  disabled={false}
                />
              </div>
              <div className="col-xxl-3 col-md-6">
                <TextInputLabel
                  name="country_name"
                  value={previousAddressDetail.country_name}
                  label="Country"
                  //error={countryError}
                  onChange={handleChange}
                  disabled={false}
                />
              </div>
              {/*  zipPostalCode*/}
              <div className="col-xxl-3 col-md-6">
                <TextInputLabel
                  name="zip_postal_code"
                  value={previousAddressDetail.zip_postal_code}
                  label="Zip/Postal Code"
                  //error={zipPostalCodeError}
                  onChange={handleChange}
                  disabled={false}
                />
              </div>

              {/*dateIn*/}
              <div className="col-xxl-3 col-md-6">
                <Input
                  showIcon
                  name="date_in"
                  value={previousAddressDetail.date_in}
                  label="Date In"
                  //error={dateInError}
                  onChange={(date) => handleChange(date, "dateIn")}
                  type={"date"}
                />
              </div>

              {/*landLordName*/}
              <div className="col-xxl-3 col-md-6">
                <TextInputLabel
                  name="land_lord_name"
                  value={previousAddressDetail.land_lord_name}
                  label="LandLord Name"
                  //error={landLordNameError}
                  onChange={handleChange}
                  disabled={false}
                />
              </div>

              {/*landLordPhone*/}
              <div className="col-xxl-3 col-md-6">
                <TextInputLabel
                  name="land_lord_phone"
                  value={previousAddressDetail.land_lord_phone}
                  label="LandLord Phone"
                  //error={landLordPhoneError}
                  onChange={handleChange}
                  disabled={false}
                  type={"number"}
                />
              </div>

              {/*monthlyRent*/}
              <div className="col-xxl-3 col-md-6">
                <TextInputLabel
                  name="monthly_rent"
                  value={previousAddressDetail.monthly_rent}
                  label="Monthly Rent"
                  //error={monthlyRentError}
                  onChange={handleChange}
                  disabled={false}
                />
              </div>
            </div>
          </div>
          {/* Form start */}

          {/* Form end */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default PreviousAddressAppliForm;
