export const getActionTypeText = (actionType) => {
  switch (actionType) {
    case "none":
      return "No action required";
    case "schedule_viewing":
      return "Schedule Viewing";
    case "complete_application":
      return "Complete Application";
    case "upload_document":
      return "Upload Document";
    case "schedule_interview":
      return "Schedule Interview";
    case "sign_lease":
      return "Sign Lease";
    case "submit_payment":
      return "Submit Payment";
    case "schedule_move_in":
      return "Schedule Move-In";
    case "report_issue":
      return "Report Issue";
    case "request_maintenance":
      return "Request Maintenance";
    case "update_contact_info":
      return "Update Contact Info";
    case "review_policies":
      return "Review Policies";
    case "confirm_details":
      return "Confirm Details";
    default:
      return "Perform Action";
  }
};
