import React, { useEffect, useState, useReducer } from "react";
import { useHistory, useNavigate } from "react-router-dom";
import { Link, useParams } from "react-router-dom";
import _ from "lodash";
import Joi from "joi-browser";
import CryptoJS from "crypto-js";
import { Bars } from "react-loader-spinner";
import Input from "../../reusable/form/Input";
import config from "../../../constants/config.json";
import { toast } from "react-toastify";
import TextInputLabel from "../../reusable/form/textinputlabel";
import CheckboxInput from "../../reusable/form/checkboxInput";
import { fetchPropertyAdvertById } from "../../../services/property/advertisement/propertyAdvertisement";
import {
  createTenantPreScreening,
  submitTenantApplicationFormToLandlord,
} from "../../../services/pre-screening/prescreeningManagementService";
import PasswordInput from "../../reusable/form/passwordInput";
import CurrentAddressAppliForm from "./sections/currentAddressAppliForm";
import PreviousAddressAppliForm from "./sections/previousAddressAppliForm";
import OtherOccupantAppliForm from "./sections/otherApplicantAppliForm";
import EmploymentAndIncomeForm from "./sections/employmentAndIncomeForm";
import {
  decryptData,
  generateKey,
  getAllApplicationSnapshotsByTenant,
  updateTenantProfileMainAppli,
} from "../../../services/tenant/masterApplication/tenantMainApplicationManagement";
import EmergencyContactForm from "./sections/emergencyContactForm";
import PersonalReferenceForm from "./sections/personalReferenceForm";
import { getCurrentTenantProfileByEmail } from "../../../services/authentication/tenantAuth";
const { v4: uuidv4 } = require("uuid");

//TODO: need to figure out how to populate the datepicker
//disable any fields pre-filled from the prescreening
//stepper for the future
//https://blog.logrocket.com/add-stepper-components-react-app/

//Need to be able to upload documents, select what category they are, by giving them a name, eg. Job Letter
//Test this vigorously before going live
const PrimaryApplicationForm = ({ isDarkMode }) => {
  //icons https://pictogrammers.github.io/@mdi/font/2.0.46/
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const [showSsnTrn, setshowSsnTrn] = useState(false);
  const [showDriversLicenseNumber, setshowDriversLicenseNumber] =
    useState(false);
  const { campaignIdFromUrl } = useParams();
  const [campaignId, setCampaignId] = useState(campaignIdFromUrl);
  const [showDropdown, setShowDropdown] = useState(!campaignIdFromUrl);

  const navigate = useNavigate();
  const [tenantProfile, setTenantProfile] = useState({
    tenant_id: "",
    is_the_main_tenant: false,
    has_completed_application_sign_up: false,
    first_name: "",
    middle_name: "",
    last_name: "",
    work_phone_number: "",
    date_of_birth: new Date(),
    main_tenant_image_url: "",
    email_address: "",
    signature_detail: [],
    current_appl_pool: {
      land_lord_id: "",
      property_id: "",
      contract_id: "",
      invite_access_code: "",
      has_access_code_been_used: false,
      is_approved_or_rejected: false,
      final_decision: "",
      application_status: "Not Started",
    },
    tenant_application: {
      latest_version: false,
      application_last_update: null,
      property_id: "",
      property_name: "",
      tenant_application_summary_data: {
        numberof_occupants: 0,
        number_of_reference_added: 0,
        combined_salary: 0,
      },
      rent_cost: [],
      start_date: "",
      referred_by_detail: [],
      applicant_info: {
        first_name: "",
        middle_initial: "",
        last_name: "",
        trn_ssn: "",
        driver_license: "",
        date_of_birth: "",
        home_phone: "",
        work_phone: "",
        email_address: "",
      },
      current_address_detail: {
        address_type: "",
        street_address: "",
        city_town: "",
        parish_state: "",
        country_name: "",
        zip_postal_code: "",
        date_in: "",
        land_lord_name: "",
        land_lord_phone: "",
        monthly_rent: "",
        record_time_stamp: null,
      },
      previous_address: {
        address_type: "",
        street_address: "",
        city_town: "",
        parish_state: "",
        country_name: "",
        zip_postal_code: "",
        date_in: "",
        land_lord_name: "",
        land_lord_phone: "",
        monthly_rent: "",
        record_time_stamp: null,
      },
      pre_screening_detail: {
        email_address: "",
        first_name: "",
        last_name: "",
        duration_of_current_residence: 0,
        total_monthly_income: 0,
        source_of_income: "",
        number_of_occupants: 0,
        reason_for_leaving: "",
        desired_move_in_date: new Date(),
        does_any_one_smoke: false,
        has_pets: false,
        describe_pets: "",
      },
      other_occupants: [
        {
          first_name: "",
          last_name: "",
          birth_year: "",
          relationship: "",
          is_over_18: false,
          email_address: "",
        },
      ],
      pets_detail: [],
      employment_income_info: [
        {
          occupation_name: "",
          employer_company: "",
          monthly_salary: {
            salary_amt: 0,
            salary_amt_cents: 0,
            currency_name: "",
          },
          supervisor_name: "",
          supervisor_phone: "",
          start_date: "",
          end_date: "",
        },
      ],
      other_income_sources: [],
      emergency_contact: [
        {
          contact_name: "",
          address: "",
          phone_number: "",
          contact_relationship: "",
        },
      ],
      personal_references: [
        {
          reference_name: "",
          address: "",
          phone_number: "",
          contact_relationship: "",
        },
      ],
      background_info: [],
      vehicle_info: [],
    },
    record_created_date: null,
    is_public: false,
  });

  const [application, setApplication] = useState({});

  const [errors, setErrors] = useState({
    tenant_id: null,
    is_the_main_tenant: null,
    has_completed_application_sign_up: null,
    first_name: null,
    middle_name: null,
    last_name: null,
    work_phone_number: null,
    date_of_birth: null,
    main_tenant_image_url: null,
    email_address: null,
    signature_detail: null,
    current_appl_pool: {
      land_lord_id: null,
      property_id: null,
      contract_id: null,
      invite_access_code: null,
      has_access_code_been_used: null,
      is_approved_or_rejected: null,
      final_decision: null,
      application_status: null,
    },
    tenant_application: {
      latest_version: null,
      application_last_update: null,
      property_id: null,
      property_name: null,
      tenant_application_summary_data: {
        numberof_occupants: null,
        number_of_reference_added: null,
        combined_salary: null,
      },
      rent_cost: null,
      start_date: null,
      referred_by_detail: null,
      applicant_info: {
        first_name: null,
        middle_initial: null,
        last_name: null,
        trn_ssn: null,
        driver_license: null,
        date_of_birth: null,
        home_phone: null,
        work_phone: null,
        email_address: null,
      },
      current_address_detail: {
        address_type: null,
        street_address: null,
        city_town: null,
        parish_state: null,
        country_name: null,
        zip_postal_code: null,
        date_in: null,
        land_lord_name: null,
        land_lord_phone: null,
        monthly_rent: null,
        record_time_stamp: null,
      },
      previous_address: {
        address_type: null,
        street_address: null,
        city_town: null,
        parish_state: null,
        country_name: null,
        zip_postal_code: null,
        date_in: null,
        land_lord_name: null,
        land_lord_phone: null,
        monthly_rent: null,
        record_time_stamp: null,
      },
      other_occupants: null,
      pets_detail: null,
      employment_income_info: null,
      other_income_sources: null,
      emergency_contact: null,
      personal_references: null,
      background_info: null,
      vehicle_info: null,
    },
    record_created_date: null,
    is_public: null,
  });

  const vehicleInfoSchema = [
    {
      make: Joi.string().allow(""),
      model: Joi.string().allow(""),
      year: Joi.number().optional(),
      color: Joi.string().allow(""),
      license_plate: Joi.string().allow(""),
    },
  ];

  const convictedOfCrimeSchema = [
    {
      has_been_convicted: Joi.boolean(),
      type_of_offence: Joi.string().allow(""),
      country_name: Joi.string().allow(""),
      state_parish: Joi.string().allow(""),
      city: Joi.string().allow(""),
      zip_code_postal_code: Joi.string().allow(""),
      date_convicted: Joi.string().allow(""),
    },
  ];

  const evictedDetailsSchema = [
    {
      has_been_evicted: Joi.boolean(),
      reason_for_eviction: Joi.string().allow(""),
      property_name: Joi.string().allow(""),
      city: Joi.string().allow(""),
      state_parish: Joi.string().allow(""),
      zip_code_postal_code: Joi.string().allow(""),
      landlord_name: Joi.string().allow(""),
    },
  ];

  const backgroundInfoSchema = {
    filed_for_bankruptcy: Joi.boolean(),
    refused_to_pay_rent: Joi.boolean(),
    evicted_details: evictedDetailsSchema,
    convicted_of_crime: convictedOfCrimeSchema,
  };

  const personalReferencesSchema = [
    {
      reference_name: Joi.string().allow(""),
      address: Joi.string().allow(""),
      phone_number: Joi.string().allow(""),
      contact_relationship: Joi.string().allow(""),
    },
  ];

  const emergencyContactSchema = [
    {
      contact_name: Joi.string().allow(""),
      address: Joi.string().allow(""),
      phone_number: Joi.string().allow(""),
      contact_relationship: Joi.string().allow(""),
    },
  ];

  const monthlyIncomeSchema = {
    income_amt: Joi.number().optional(),
    income_amt_cents: Joi.number().optional(),
    currency_name: Joi.string().allow(""),
  };

  const otherIncomeSourcesSchema = [
    {
      other_income_description: Joi.string().allow(""),
      monthly_income: monthlyIncomeSchema,
    },
  ];

  const monthlySalarySchema = {
    salary_amt: Joi.number().optional(),
    salary_amt_cents: Joi.number().optional(),
    currency_name: Joi.string().allow(""),
  };

  const employmentIncomeInfoSchema = [
    {
      occupation_name: Joi.string().allow(""),
      employer_company: Joi.string().allow(""),
      monthly_salary: monthlySalarySchema,
      supervisor_name: Joi.string().allow(""),
      supervisor_phone: Joi.string().allow(""),
      start_date: Joi.string().allow(""),
      end_date: Joi.string().allow(""),
    },
  ];

  const petsDetailSchema = [
    {
      pet_type: Joi.string().allow(""),
      describe_pet: Joi.string().allow(""),
    },
  ];
  const rentCostSchema = [
    {
      rent_amt: Joi.number().optional(),
      rent_amt_cents: Joi.number().optional(),
      currency_name: Joi.string().allow(""),
    },
  ];

  const otherOccupantsSchema = [
    {
      first_name: Joi.string().allow(""),
      last_name: Joi.string().allow(""),
      birth_year: Joi.number().optional(),
      relationship: Joi.string().allow(""),
      is_over_18: Joi.boolean(),
      email_address: Joi.string().allow(""),
    },
  ];

  const currentApplPoolSchema = {
    land_lord_id: Joi.string().allow(""),
    property_id: Joi.string().allow(""),
    contract_id: Joi.string().allow(""),
    invite_access_code: Joi.string().allow(""),
    has_access_code_been_used: Joi.boolean(),
    is_approved_or_rejected: Joi.boolean(),
    final_decision: Joi.string().allow(""),
    application_status: Joi.string().valid(
      "Not Started",
      "In Progress",
      "Completed"
    ),
  };

  const referredByDetailSchema = [
    {
      agent_name: Joi.string().allow(""),
    },
  ];

  const tenantApplicationSummaryDataSchema = {
    numberof_occupants: Joi.number(),
    number_of_reference_added: Joi.number(),
    combined_salary: Joi.number(),
  };

  const applicantInfoSchema = {
    first_name: Joi.string().allow(""),
    middle_initial: Joi.string().allow(""),
    last_name: Joi.string().allow(""),
    trn_ssn: Joi.string().allow(""),
    driver_license: Joi.string().allow(""),
    date_of_birth: Joi.string().allow(""),
    home_phone: Joi.string().allow(""),
    work_phone: Joi.string().allow(""),
    email_address: Joi.string().email().allow(""),
  };

  const currentAddressDetailSchema = {
    address_type: Joi.string().allow(""),
    street_address: Joi.string().required().min(5),
    city_town: Joi.string().allow(""),
    parish_state: Joi.string().allow(""),
    country_name: Joi.string().allow(""),
    zip_postal_code: Joi.string().allow(""),
    date_in: Joi.string().allow(""),
    land_lord_name: Joi.string().allow(""),
    land_lord_phone: Joi.string().allow(""),
    monthly_rent: Joi.string().allow(""),
    record_time_stamp: Joi.date().allow(null),
  };

  const previousAddressSchema = {
    address_type: Joi.string().allow(""),
    street_address: Joi.string().required().min(5),
    city_town: Joi.string().allow(""),
    parish_state: Joi.string().allow(""),
    country_name: Joi.string().allow(""),
    zip_postal_code: Joi.string().allow(""),
    date_in: Joi.string().allow(""),
    land_lord_name: Joi.string().allow(""),
    land_lord_phone: Joi.string().allow(""),
    monthly_rent: Joi.string().allow(""),
    record_time_stamp: Joi.date().allow(null),
  };

  const tenantApplicationSchema = {
    latest_version: Joi.boolean(),
    application_last_update: Joi.date().allow(null),
    property_id: Joi.string().allow(""),
    property_name: Joi.string().allow(""),
    tenant_application_summary_data: tenantApplicationSummaryDataSchema,
    rent_cost: rentCostSchema,
    start_date: Joi.string().allow(""),
    referred_by_detail: referredByDetailSchema,
    applicant_info: applicantInfoSchema,
    current_address_detail: currentAddressDetailSchema,
    previous_address: previousAddressSchema,
    other_occupants: otherOccupantsSchema,
    pets_detail: petsDetailSchema,
    employment_income_info: employmentIncomeInfoSchema,
    other_income_sources: otherIncomeSourcesSchema,
    emergency_contact: emergencyContactSchema,
    personal_references: personalReferencesSchema,
    background_info: backgroundInfoSchema,
    vehicle_info: vehicleInfoSchema,
  };

  const signatureDetailSchema = [
    {
      signature_image_url: Joi.string().allow(""),
      signature_record_created_date: Joi.date().allow(null),
    },
  ];

  const schema = {
    tenant_id: Joi.string().allow(""),
    is_the_main_tenant: Joi.boolean(),
    has_completed_application_sign_up: Joi.boolean(),
    first_name: Joi.string().allow(""),
    middle_name: Joi.string().allow(""),
    last_name: Joi.string().allow(""),
    cell_phone_number: Joi.string().optional().allow(""),
    work_phone_number: Joi.string().required(),
    date_of_birth: Joi.date(),
    main_tenant_image_url: Joi.string().allow(""),
    email_address: Joi.string().email().allow(""),
    signature_detail: signatureDetailSchema,
    current_appl_pool: currentApplPoolSchema,
    tenant_application: tenantApplicationSchema,
    record_created_date: Joi.date().allow(null),
    is_public: Joi.boolean(),
    ssn_trn: Joi.string().optional().allow(""),
  };

  const [isOver18, setIsOver18] = useState([false]);

  const [Loading, setLoading] = useState({
    isLoading: false,
  });

  useEffect(() => {
    setLoading({ isLoading: true });
    window.scrollTo(0, 0);
    toast.warning(
      "Don't use this form, it's not ready yet, the form will be sent as a PDF via email from the landlord, sorry for the inconvenience.",
      {
        autoClose: false,
        closeOnClick: true,
        draggable: false,
      }
    );
    getAdvertDetail();
    getTenantProfile();
    setLoading({ isLoading: false });
  }, [tenantProfile.tenant_id]);

  const [allContracts, setAllContracts] = useState([
    {
      tenant_id: "",
      is_the_main_tenant: false,
      has_completed_application_sign_up: false,
      first_name: "",
      middle_name: "",
      last_name: "",
      work_phone_number: "",
      date_of_birth: new Date(),
      main_tenant_image_url: "",
      email_address: "",
      signature_detail: [],
      current_appl_pool: {
        land_lord_id: "",
        property_id: "",
        contract_id: "",
        invite_access_code: "",
        has_access_code_been_used: false,
        is_approved_or_rejected: false,
        final_decision: "",
        application_status: "Not Started",
      },
      tenant_application: {
        latest_version: false,
        application_last_update: null,
        property_id: "",
        property_name: "",
        tenant_application_summary_data: {
          numberof_occupants: 0,
          number_of_reference_added: 0,
          combined_salary: 0,
        },
        rent_cost: [],
        start_date: "",
        referred_by_detail: [],
        applicant_info: {
          first_name: "",
          middle_initial: "",
          last_name: "",
          trn_ssn: "",
          driver_license: "",
          date_of_birth: "",
          home_phone: "",
          work_phone: "",
          email_address: "",
        },
        current_address_detail: {
          address_type: "",
          street_address: "",
          city_town: "",
          parish_state: "",
          country_name: "",
          zip_postal_code: "",
          date_in: "",
          land_lord_name: "",
          land_lord_phone: "",
          monthly_rent: "",
          record_time_stamp: null,
        },
        pre_screening_detail: {
          email_address: "",
          first_name: "",
          last_name: "",
          duration_of_current_residence: 0,
          total_monthly_income: 0,
          source_of_income: "",
          number_of_occupants: 0,
          reason_for_leaving: "",
          desired_move_in_date: new Date(),
          does_any_one_smoke: false,
          has_pets: false,
          describe_pets: "",
        },
        previous_address_detail: [],
        other_occupants: [],
        pets_detail: [],
        employment_income_info: [],
        other_income_sources: [],
        emergency_contact: [],
        personal_references: [],
        background_info: [],
        vehicle_info: [],
      },
      record_created_date: null,
      is_public: false,
      property_unit_advertisement_listing: {
        advert_id: "",
        landlord_id: "",
        property_unit_id: "",
        date_advert_ends: "",
        campaign_id: "",
        property_unit: {
          property_unit_id: "",
          rental_property_id: "",
          unit_name: "",
          rent_receipt_sequence: 0,
          rental_unit_price: 0,
          rental_unit_amt_currency: "USD",
          unit_type: "",
          unit_current_rental_status: "Vacant",
          unit_specifications: {
            number_of_bedrooms: 0,
            unit_square_footage: 0,
            parking_detail: {
              driveway_type: "",
              parking_space: 0,
            },
            number_of_rooms: 0,
            number_of_bathrooms: 0,
            number_of_half_bathroom: 0,
            has_kitchen: false,
            has_patios: false,
            has_porch: false,
            has_dining_room: false,
            has_half_bathrooms: false,
            has_living_room: false,
            has_car_garage: false,
          },
          pet_specifications: {
            allow_pets: false,
            max_number_of_pets_allowed: 0,
            pets_allowed: [
              {
                animal_name: "",
                animal_type: "",
                animal_weight_max: 0,
              },
            ],
          },
          date_last_rented: "",
          record_created_date: "",
          is_rented: false,
          is_activated: true,
        },
        unit_advert_image_urls: [""],
        security_deposit: 0,
        currency_id: "",
        currency_name: "",
        is_advert_expired: false,
      },
    },
  ]);

  const getAllApplicationSnapshots = async (tenantId) => {
    const { data: allContracts } = await getAllApplicationSnapshotsByTenant(
      tenantId
    );
    setAllContracts(allContracts);
  };

  const handleCampaignChange = (event) => {
    setCampaignId(event.target.value);
  };

  const getTenantProfile = async () => {
    const email = localStorage.getItem(config.email);
    console.log(email);
    const { data: tenantProfile } = await getCurrentTenantProfileByEmail;

    getAllApplicationSnapshots(tenantProfile.tenant_id);

    const transportKey = generateKey(tenantProfile);

    if (tenantProfile.ssn_trn) {
      tenantProfile.ssn_trn = decryptData(tenantProfile.ssn_trn, transportKey);
      tenantProfile.drivers_license_number = decryptData(
        tenantProfile.drivers_license_number,
        transportKey
      );
    }

    if (tenantProfile.date_of_birth) {
      const decryptedDateOfBirth = await decryptData(
        tenantProfile.date_of_birth,
        transportKey
      );
      tenantProfile.date_of_birth = decryptedDateOfBirth;
    }

    setTenantProfile(tenantProfile);
  };

  const getAdvertDetail = async () => {
    const { data: advertDetail } = await fetchPropertyAdvertById(campaignId);
    console.log(advertDetail);
  };

  const validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(tenantProfile, schema, options);
    if (!error) return null;

    const errors = {};

    for (let item of error.details) errors[item.path[0]] = item.message;

    return errors;
  };

  const validateProperty = ({ name, value }) => {
    let obj = {};
    let schemaLocal = {};

    // Use lodash's set function to set the value at path of object
    _.set(obj, name, value);

    // Use lodash's get function to get the value at path of object
    const schemaValue = _.get(schema, name);

    if (schemaValue) {
      _.set(schemaLocal, name, schemaValue);
    } else {
      return `No schema defined for ${name}`;
    }

    const { error } = Joi.validate(obj, schemaLocal);

    return error ? error.details[0].message : null;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    //get the values from the form
    // var emailAddress = this.emailAddress.current.value;
    // var password = document.getElementById('txtPassword').value;
    //call the server to save the changes

    const errors = validate();
    // console.log(errors);
    //show the toasts for the errors
    for (const key in errors) {
      if (errors.hasOwnProperty(key)) {
        const element = errors[key];
        toast.error(element);
      }
    }

    //toast.error(errors.password);
    setErrors({ errors: errors || {} });
    if (errors) return;

    console.log(tenantProfile.desired_move_in_date);

    doSubmit();
  };

  //Change current and previous to work like other occupants
  function handleChange(eventOrValue) {
    let value, type, checked, name;

    // Check if the first argument is an event or a value
    if (eventOrValue && eventOrValue.target) {
      ({ value, type, checked, name } = eventOrValue.target);
    } else {
      ({ value, name } = eventOrValue);
      type = "date";
    }

    let errorMessage = "";
    let newData = { ...tenantProfile };

    if (type === "checkbox") {
      newData[name] = checked;
    } else if (name === "desired_move_in_date" || name === "date_of_birth") {
      newData[name] = value; // Use the date string directly
    } else {
      // Check if it's a nested property
      /*
      if (name.startsWith('tenantApplication.currentAddressDetail.')) {
        const key = name.split('.')[2];
        newData.tenantApplication.currentAddressDetail[key] = value;
      } else
      
      
      if (name.startsWith('tenantApplication.previousAddress.')) {
        const key = name.split('.')[2];
        newData.tenantApplication.previousAddress[key] = value;
      } else {
        newData[name] = value;
      }
      */
      newData[name] = value;

      errorMessage = validateProperty({ name, value });
    }

    // Update the errors state immediately with the new error message
    setErrors((prevState) => ({
      ...prevState,
      [name]: errorMessage || null,
    }));

    setTenantProfile(newData);
  }

  const doSubmit = async () => {
    //login function
    try {
      //TODO: NEXT

      //also have a version directly in the campaign that already knows the campaignId
      //alert("Need to let the user select the campaign they're applying to");
      /*alert(
        'Add all the data to the application like the prescreening, current address, etc'
      );*/
      //const { data } = this.state;
      //FORMAT TO MATCH THE DETAILS NEEDED FROM THE END POINT
      alert("Submitting Application: build the object to submit");
      return;
      const prescreeningDetail = {
        email_address: tenantProfile.email_address,

        first_name: tenantProfile.first_name,
        last_name: tenantProfile.last_name,
        duration_of_current_residence:
          tenantProfile.duration_of_current_residence,
        total_monthly_income: tenantProfile.total_monthly_income,
        source_of_income: tenantProfile.source_of_income,
        number_of_occupants: tenantProfile.number_of_occupants,
        reason_for_leaving: tenantProfile.reason_for_leaving,
        desired_move_in_date: tenantProfile.desired_move_in_date,
        does_any_one_smoke: tenantProfile.does_any_one_smoke,
        has_pets: tenantProfile.has_pets,
        describe_pets: tenantProfile.describe_pets,
      };

      const tenantProfile = {
        tenant_id: tenantProfile.tenant_id, //uuidv4(), //isnt new
        first_name: tenantProfile.first_name,
        middle_name: tenantProfile.middle_name,
        last_name: tenantProfile.last_name,
        email_address: tenantProfile.email_address,
        //password: tenantProfile.password,
        main_tenant_image_url: "",
        pre_screening_detail: prescreeningDetail,
        ssn_trn: tenantProfile.ssn_trn,
        drivers_license_number: tenantProfile.drivers_license_number,
        date_of_birth: tenantProfile.date_of_birth,
        cell_phone_number: tenantProfile.cell_phone_number,
      };

      // console.log(`Unit ID: ${propertyUnitAdvert.propertyUnitId}`);
      const prescreenFullApplication = {
        application_template_id: "",
        campaign_id: campaignId, //set from the campaign info
        // landlordId: propertyUnitAdvert.landlordId, //set from the campaign info
        //   propertyUnitId: propertyUnitAdvert.propertyUnitId, //set from the campaign info
        // tenantProfile: tenantProfile,
        pre_screening_detail: prescreeningDetail,
      };

      console.log(tenantProfile);

      console.log(
        "Store the latest version of the application then submit snapshot to the landlord"
      );

      //TODO: need the endpoint to be created
      const { data: response } = await submitTenantApplicationFormToLandlord(
        prescreenFullApplication
      );

      console.log("Response");
      console.log(response);

      //Need to add the above data into
      //tenant and its prescreeningDetails (master), also the
      //tenantRentalApplicationSnapshotScehma (copy)
      //snapshot prescreening cant be adjusted,
      //when the entire snapshot submitted the tenant cant change it
      //full application
      // NEXT;
      //console.log(loginPayLoad);
      //alert("Login called");
      //return;
      //const test = await authLoginLandlord(loginPayLoad);
      //Not working
      // alert(`Login called ${test}`);

      //remove this after testing

      toast.success("Submitted Successfully");

      //force update
      forceUpdate();

      //force a reload of the page
      window.location = "/dashboard";

      navigate("/dashboard");
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        //const errors = { ...errors };
        toast.error(ex.response.data);
        errors.email_address = ex.response.data;
        //Update the UI
        setErrors({ errors });
      }
    }
  };

  //TODO: need the ability to save at any time
  const handlePreSave = () => {
    //Store what the user has entered so far
    try {
      //alert('Updating master form');
      console.log("New Data Saving");
      console.log(tenantProfile);
      console.log(tenantProfile.previous_address);
      //alert(tenantProfile.dateOfBirth);
      // updateTenantProfileMainAppli(tenantProfile)
      const { data: response } = updateTenantProfileMainAppli(tenantProfile);
      console.log("Response");
      console.log(response);
    } catch (ex) {
      toast.error(ex.response.data);
    }

    //save what is currently in the entire form and all sections
    //the submit will do the full validation
    //this only stores to the tenantProfile -> application

    toast.success("Saved Successfully");
  };

  //TODO: need the ability to save at any time

  return (
    <React.Fragment>
      {/*Loader */}
      {Loading.isLoading && (
        <div
          id="global-loader"
          style={{
            zIndex: "99",
            justifyContent: "center",
            marginLeft: "auto",
            marginRight: "auto",
            position: "absolute",
          }}
        >
          <Bars
            height="80"
            width="80"
            color="#4fa94d"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperclassName=""
            visible={true}
          />
        </div>
      )}
      {/*/Loader */}
      {/* prefil things from the prescreening */}

      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header d-flex flex-column">
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <h4 className="card-title mb-0">
                          Primary Application Form
                        </h4>
                        <h6
                          className="card-subtitle text-muted"
                          style={{ paddingTop: "0.5%" }}
                        >
                          This is your copy, when you are submitting an
                          application to a landlord, they will only get a
                          snapshot of this information.
                        </h6>
                      </div>

                      {/* Save Form at anytime located to the right of the form corner*/}
                      {/*No validation since its saving what we currently have, when submit you have
                        to select the campaign (landlord your sending it to from your list of campaigns that are active) */}
                      <div>
                        <button
                          className="btn btn-primary"
                          type="submit"
                          onClick={() => {
                            handlePreSave();
                          }}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* end card header */}
                  <form onSubmit={handleSubmit} className="card-body">
                    <div className="live-preview">
                      <div className="row gy-4">
                        {/*TextField start*/}
                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <TextInputLabel
                              name="first_name"
                              value={tenantProfile.first_name}
                              label="First Name"
                              error={errors.first_name}
                              onChange={handleChange}
                              disabled={true}
                            />
                          </div>
                        </div>
                        {/*TextField end*/}

                        {/*TextField start*/}
                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <TextInputLabel
                              name="middle_name"
                              value={tenantProfile.middle_name}
                              label="Middle Name"
                              error={errors.middle_name}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        {/*TextField end*/}
                        {/*end col*/}
                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <TextInputLabel
                              name="last_name"
                              value={tenantProfile.last_name}
                              label="Last Name"
                              error={errors.last_name}
                              onChange={handleChange}
                              disabled={true}
                            />
                          </div>
                        </div>
                        {/*end col*/}
                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <TextInputLabel
                              name="email_address"
                              value={tenantProfile.email_address}
                              label="Email Address"
                              error={errors.email_address}
                              onChange={handleChange}
                              type={"email"}
                              disabled={true}
                            />
                          </div>
                        </div>
                        {/*end col*/}
                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <Input
                              showIcon
                              name="date_of_birth"
                              value={tenantProfile.date_of_birth}
                              label="Birth Date"
                              error={errors.date_of_birth}
                              onChange={handleChange}
                              type={"date"}
                            />
                          </div>
                        </div>
                        {/*end col*/}
                        <div className="col-xxl-3 col-md-6">
                          <PasswordInput
                            name="ssn_trn"
                            label="SSN/TRN"
                            value={tenantProfile.ssn_trn}
                            error={errors.ssn_trn}
                            onChange={handleChange}
                            showPassword={showSsnTrn}
                            setShowPassword={setshowSsnTrn}
                          />
                        </div>

                        <div className="col-xxl-3 col-md-6">
                          <PasswordInput
                            name="drivers_license_number"
                            label="Drivers License Number"
                            value={tenantProfile.drivers_license_number}
                            error={errors.drivers_license_number}
                            onChange={handleChange}
                            showPassword={showDriversLicenseNumber}
                            setShowPassword={setshowDriversLicenseNumber}
                          />
                        </div>
                        {/*end col*/}

                        {/*Cellphone */}
                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <TextInputLabel
                              name="cell_phone_number"
                              value={tenantProfile.cell_phone_number}
                              label="Cell Phone Number"
                              error={errors.cell_phone_number}
                              onChange={handleChange}
                              type={"text"}
                            />
                          </div>
                        </div>
                        {/*end col*/}

                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <TextInputLabel
                              name="work_phone_number"
                              value={tenantProfile.work_phone_number}
                              label="Work Phone Number"
                              error={errors.work_phone_number}
                              onChange={handleChange}
                              type={"text"}
                            />
                          </div>
                        </div>
                        {/*end col*/}

                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <TextInputLabel
                              name="duration_of_current_residence"
                              value={
                                tenantProfile &&
                                tenantProfile.pre_screening_detail
                                  ? tenantProfile.pre_screening_detail
                                      .duration_of_current_residence
                                  : ""
                              }
                              label="Duration of Current Residence (in months)"
                              error={errors.duration_of_current_residence}
                              onChange={handleChange}
                              type={"number"}
                              disabled={true}
                            />
                          </div>
                        </div>
                        {/*end col*/}
                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <TextInputLabel
                              name="total_monthly_income"
                              value={
                                tenantProfile &&
                                tenantProfile.pre_screening_detail
                                  ? tenantProfile.pre_screening_detail
                                      .total_monthly_income
                                  : ""
                              }
                              label="Total Monthly Income"
                              error={errors.total_monthly_income}
                              onChange={handleChange}
                              type={"number"}
                              disabled={true}
                            />
                          </div>
                        </div>
                        {/*end col*/}
                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <TextInputLabel
                              name="source_of_income"
                              value={
                                tenantProfile &&
                                tenantProfile.pre_screening_detail
                                  ? tenantProfile.pre_screening_detail
                                      .source_of_income
                                  : ""
                              }
                              label="Source of Income"
                              error={errors.source_of_income}
                              onChange={handleChange}
                              type={"text"}
                              disabled={true}
                            />
                          </div>
                        </div>
                        {/*end col*/}
                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <TextInputLabel
                              name="number_of_occupants"
                              value={
                                tenantProfile &&
                                tenantProfile.pre_screening_detail
                                  ? tenantProfile.pre_screening_detail
                                      .number_of_occupants
                                  : ""
                              }
                              label="Total Number of Occupants"
                              error={errors.number_of_occupants}
                              onChange={handleChange}
                              type={"number"}
                              disabled={true}
                            />
                          </div>
                        </div>
                        {/*end col*/}
                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <TextInputLabel
                              name="reason_for_leaving"
                              value={
                                tenantProfile &&
                                tenantProfile.pre_screening_detail
                                  ? tenantProfile.pre_screening_detail
                                      .reason_for_leaving
                                  : ""
                              }
                              label="Reason for Leaving"
                              error={errors.reason_for_leaving}
                              onChange={handleChange}
                              type={"textarea"}
                              disabled={true}
                            />
                          </div>
                        </div>
                        {/*end col*/}

                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <CheckboxInput
                              name="does_any_one_smoke"
                              label="Does Any of the Occupants Smoke?"
                              checked={
                                tenantProfile &&
                                tenantProfile.pre_screening_detail
                                  ? tenantProfile.pre_screening_detail
                                      .does_any_one_smoke
                                  : false
                              }
                              onChange={handleChange}
                              disabled={true}
                            />
                          </div>
                        </div>
                        {/*end col*/}
                        <div className="col-xxl-3 col-md-6">
                          <div>
                            <CheckboxInput
                              name="has_pets"
                              label="Do you have any pets?"
                              checked={
                                tenantProfile &&
                                tenantProfile.pre_screening_detail
                                  ? tenantProfile.pre_screening_detail.has_pets
                                  : false
                              }
                              onChange={handleChange}
                              disabled={true}
                            />
                          </div>
                        </div>
                        {/*end col*/}
                        {tenantProfile &&
                          tenantProfile.pre_screening_detail &&
                          tenantProfile.pre_screening_detail.has_pets && (
                            <div className="col-xxl-3 col-md-6">
                              <div>
                                <TextInputLabel
                                  name="describe_pets"
                                  value={
                                    tenantProfile.pre_screening_detail
                                      .describe_pets
                                  }
                                  label="Please Describe Your Pets"
                                  error={errors.describe_pets}
                                  onChange={handleChange}
                                  type={"textarea"}
                                  disabled={true}
                                />
                              </div>
                            </div>
                          )}
                        {/*end col*/}
                      </div>
                      {/*end row*/}
                      <CurrentAddressAppliForm
                        tenantProfile={tenantProfile}
                        setTenantProfile={setTenantProfile}
                      />
                      {/* Previous Address DO it the same way*/}
                      {/* Do we need more than one previous addresses?*/}
                      <PreviousAddressAppliForm
                        tenantProfile={tenantProfile}
                        setTenantProfile={setTenantProfile}
                      />
                      <OtherOccupantAppliForm
                        tenantProfile={tenantProfile}
                        setTenantProfile={setTenantProfile}
                      />

                      <EmploymentAndIncomeForm
                        tenantProfile={tenantProfile}
                        setTenantProfile={setTenantProfile}
                      />

                      {/*Emergency Contact*/}
                      <EmergencyContactForm
                        tenantProfile={tenantProfile}
                        setTenantProfile={setTenantProfile}
                      />

                      {/*Personal References*/}
                      <PersonalReferenceForm
                        tenantProfile={tenantProfile}
                        setTenantProfile={setTenantProfile}
                      />

                      {/*Background Info - After the submit application function is added*/}

                      {/*Sign your name before submitting this information and reading the disclaimer*/}
                      {/*Show if no campaignId found */}
                      {campaignId}
                      {showDropdown && (
                        <select
                          value={campaignId}
                          onChange={handleCampaignChange}
                        >
                          <option value="">Select a campaign</option>
                          {allContracts.map((contract) => (
                            <option
                              key={
                                contract.property_unit_advertisement_listing
                                  .campaign_id
                              }
                              value={
                                contract.property_unit_advertisement_listing
                                  .campaign_id
                              }
                            >
                              {
                                contract.property_unit_advertisement_listing
                                  .property_unit.unit_name
                              }
                            </option>
                          ))}
                        </select>
                      )}

                      {/*Register Button*/}
                      <div className="row gy-4" style={{ paddingTop: "2%" }}>
                        <div className="col-lg-12">
                          <div className="d-grid">
                            <button
                              className="btn btn-primary"
                              disabled={!campaignId}
                              type="submit"
                            >
                              Submit (Will be enabled once you have viewed the
                              unit) Save in the meantime
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PrimaryApplicationForm;
