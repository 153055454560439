import React, { useState, useEffect, useReducer } from "react";
import { useHistory, useNavigate } from "react-router-dom";
import { Link, useParams } from "react-router-dom";

import Joi from "joi-browser";

import { Bars } from "react-loader-spinner";

import { toast } from "react-toastify";

import TextInputLabel from "../../../reusable/form/textinputlabel";
import SelectInputLabel from "../../../reusable/form/selectinputlabel";
import Input from "../../../reusable/form/Input";
//if any applicant is over 18, they will have to fill out the full information about
//where they have lived,their identification, and their employment history, their background information

//the applicant is count is set from the prescreening
//MARK: Need to style it
//Make it in isolation first then the data back to the main form after
const EmploymentAndIncomeForm = ({ tenantProfile, setTenantProfile }) => {
  //determine if the applicant is over 18 after they enter the birthyear
  //if the user entry isnt found here it will show as other in the other field else will just show from the list

  //use the Same list for the prescreening
  const occupations = [
    "Software Developer",
    "Doctor",
    "Teacher",
    "Engineer",
    "Nurse",
    "Accountant",
    "Manager",
    "Salesperson",
    "Lawyer",
    "Marketing Specialist",
    "Other",
  ];

  const [employmentIncomeInfos, setEmploymentIncomeInfos] = useState(
    tenantProfile.tenantApplication.employmentIncomeInfo || [
      {
        occupation_name: "",
        employer_company: "",
        monthly_salary: {
          salary_amt: 0,
          salary_amt_cents: 0,
          currency_name: "",
        },
        supervisor_name: "",
        supervisor_phone: "",
        start_date: "",
        end_date: "",
      },
    ]
  );

  useEffect(() => {
    setEmploymentIncomeInfos(
      tenantProfile.tenantApplication.employmentIncomeInfo || [
        {
          occupation_name: "",
          employer_company: "",
          monthly_salary: {
            salary_amt: 0,
            salary_amt_cents: 0,
            currency_name: "",
          },
          supervisor_name: "",
          supervisor_phone: "",
          start_date: "",
          end_date: "",
        },
      ]
    );
  }, [tenantProfile.tenantApplication.employmentIncomeInfo]);

  const handleChange = (i, eventOrValue, name) => {
    const values = [...employmentIncomeInfos];

    // Check if eventOrValue is an event object or a direct value
    const isEvent = eventOrValue && eventOrValue.target;
    const fieldName = isEvent ? eventOrValue.target.name : name;
    const value = isEvent ? eventOrValue.target.value : eventOrValue;

    if (fieldName in values[i]) {
      values[i][fieldName] = value;
    } else {
      // Handle nested objects like monthlySalary
      for (let key in values[i]) {
        if (
          typeof values[i][key] === "object" &&
          values[i][key] !== null &&
          fieldName in values[i][key]
        ) {
          values[i][key][fieldName] = value;
        }
      }
    }

    setEmploymentIncomeInfos(values);

    setTenantProfile((prevProfile) => ({
      ...prevProfile,
      employmentApplication: {
        ...prevProfile.employmentApplication,
        employmentIncomeInfos: values,
      },
    }));
  };

  const handleAdd = () => {
    const values = [...employmentIncomeInfos];
    values.push({
      occupation_name: "",
      employer_company: "",
      monthly_salary: {
        salary_amt: 0,
        salary_amt_cents: 0,
        currency_name: "",
      },
      supervisor_name: "",
      supervisor_phone: "",
      start_date: "",
      end_date: "",
    });
    setEmploymentIncomeInfos(values);

    setTenantProfile((prevProfile) => ({
      ...prevProfile,
      tenantApplication: {
        ...prevProfile.tenantApplication,
        employmentIncomeInfo: values,
      },
    }));
  };

  const handleRemove = (i) => {
    const values = [...employmentIncomeInfos];
    values.splice(i, 1);
    setEmploymentIncomeInfos(values);

    setTenantProfile((prevProfile) => ({
      ...prevProfile,
      tenantApplication: {
        ...prevProfile.tenantApplication,
        employmentIncomeInfo: values,
      },
    }));
  };

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="card-header d-flex flex-column">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h4 className="card-title mb-0">
                Employment and Income Information
              </h4>
              <h6
                className="card-subtitle text-muted"
                style={{ paddingTop: "0.5%" }}
              >
                {/*subHeadline */}
              </h6>
            </div>

            {/* Save Form at anytime located to the right of the form corner*/}
            {/*No validation since its saving what we currently have, when submit you have
                        to select the campaign (landlord your sending it to from your list of campaigns that are active) */}
            <div>{/* The right side*/}</div>
          </div>
          {/*Revisit validation later */}

          {employmentIncomeInfos.length === 0 && (
            <div className="row gy-4 pt-3">
              {/* Message to tell the user to press Add Employment and Income Button to add this info*/}
              <div className="col-xxl-12 col-md-12">
                <div
                  className="alert alert-info alert-dismissible fade show"
                  role="alert"
                >
                  <i className="mdi mdi-bullseye-arrow me-2"></i>
                  <strong>
                    Press Add Employment and Income Button to add this
                    information
                  </strong>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                  ></button>
                </div>
              </div>
            </div>
          )}

          <div className="col-lg-12">
            {employmentIncomeInfos.map((employment, idx) => (
              <div className="row gy-4" key={`${employment}-${idx}`}>
                {/*
                <div className='col-xxl-3 col-md-6'>
                  <SelectInputLabel
                    name='occupations'
                    label='Occupations'
                    value={employment.occupationName}
                    onChange={(e) => handleChange(idx, e)}
                  >
                    <option value=''>Select...</option>
                    {occupations.map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                  </SelectInputLabel>
                </div>

                <div className='col-xxl-3 col-md-6'>
                  <TextInputLabel
                    name='occupationName'
                    label='Occupation Name'
                    value={employment.occupationName}
                    onChange={(e) => handleChange(idx, e)}
                  />
                </div>
                */}

                <div className="col-xxl-3 col-md-6">
                  <SelectInputLabel
                    name="occupations"
                    label="Occupations"
                    value={
                      occupations.includes(employment.occupation_name)
                        ? employment.occupation_name
                        : "Other"
                    }
                    onChange={(e) => {
                      const selectedOccupation = e.target.value;
                      if (
                        selectedOccupation === "Other" ||
                        !occupations.includes(selectedOccupation)
                      ) {
                        // If 'Other' is selected or the selected occupation is not in the occupations array,
                        // set the occupationName to an empty string and show the text input field.
                        handleChange(idx, {
                          target: { name: "occupation_name", value: "" },
                        });
                      } else {
                        // If a valid occupation is selected, set the occupationName to the selected occupation
                        // and hide the text input field.
                        handleChange(idx, {
                          target: {
                            name: "occupation_name",
                            value: selectedOccupation,
                          },
                        });
                      }
                    }}
                  >
                    <option value="">Select...</option>
                    {occupations.map((type) => (
                      <option key={type} value={type}>
                        {type}
                      </option>
                    ))}
                    <option value="Other">Other</option>
                  </SelectInputLabel>
                </div>

                {!occupations.includes(employment.occupation_name) && (
                  <div className="col-xxl-3 col-md-6">
                    <TextInputLabel
                      name="occupation_name"
                      label="Occupation Name"
                      value={employment.occupation_name}
                      onChange={(e) => handleChange(idx, e)}
                    />
                  </div>
                )}

                <div className="col-xxl-3 col-md-6">
                  <TextInputLabel
                    name="employer_company"
                    label="Employer Company"
                    value={employment.employer_company}
                    onChange={(e) => handleChange(idx, e)}
                  />
                </div>
                <div className="col-xxl-3 col-md-6">
                  <TextInputLabel
                    type="number"
                    label="Salary Amount"
                    name="salary_amt"
                    value={employment.monthly_salary.salary_amt}
                    onChange={(e) => handleChange(idx, e)}
                  />
                </div>
                <div className="col-xxl-3 col-md-6">
                  <TextInputLabel
                    type="text"
                    label="Currency Name"
                    name="currency_name"
                    value={employment.monthly_salary.currency_name}
                    onChange={(e) => handleChange(idx, e)}
                  />
                </div>

                <div className="col-xxl-3 col-md-6">
                  <TextInputLabel
                    type="text"
                    label="Supervisor Name"
                    name="supervisor_name"
                    value={employment.supervisor_name}
                    onChange={(e) => handleChange(idx, e)}
                  />
                </div>

                <div className="col-xxl-3 col-md-6">
                  <TextInputLabel
                    type="text"
                    label="Supervisor Phone Number"
                    name="supervisor_phone"
                    value={employment.supervisor_phone}
                    onChange={(e) => handleChange(idx, e)}
                  />
                </div>

                <div className="col-xxl-3 col-md-6">
                  <Input
                    showIcon
                    name="start_date"
                    value={employment.start_date}
                    label="Start Date"
                    onChange={(date) => handleChange(idx, date, "start_date")}
                    type={"date"}
                  />
                </div>

                <div className="col-xxl-3 col-md-6">
                  <Input
                    showIcon
                    name="end_date"
                    value={employment.end_date}
                    label="End Date"
                    onChange={(date) => handleChange(idx, date, "end_date")}
                    type={"date"}
                  />
                </div>

                {/*toggle to say present*/}

                <div className="col-xxl-1 col-md-1">
                  <button
                    type="button"
                    className="btn btn-danger btn-icon waves-effect waves-light"
                    onClick={() => handleRemove(idx)}
                  >
                    <i class="ri-delete-bin-5-line"></i>
                  </button>
                </div>

                {/* horizontal divider line*/}
                <hr />
              </div>
            ))}
          </div>
          <div className="col-xxl-3 col-md-6">
            <button
              type="button"
              className="btn btn-success w-lg waves-effect waves-light"
              onClick={() => handleAdd()}
            >
              <i className="ri-add-fill"></i>
              Add Employment and Income
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmploymentAndIncomeForm;
